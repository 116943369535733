import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodyStandardStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class AreaOfInterestChip extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      /* if true, a button will appear on element which fires the 'nova-chip-remove' event when clicked */
      removable: { type: Boolean, reflect: true },
      /* skill object passed to element, mainly pulls name of skill */
      areaOfInterest: { type: Object },
      /* custom 'text' string will be displayed within chip component */
      text: { type: String },
      /* used for providing more context during hover/interaction, defaults to text when not used */
      titleText: { type: String, attribute: 'title-text', reflect: true },
      /* limits the max width of the chip to --area-of-interest-chip-truncate-width */
      truncate: { type: Boolean, reflect: true },
      /* private: is true if component is to display an icon */
      _hasIcon: { type: Boolean, attribute: 'has-icon', reflect: true },
    };
  }

  static get styles() {
    return [
      bodyStandardStyles,
      css`
        :host {
          --area-of-interest-chip-background-color: white;
          --area-of-interest-chip-border: 1px solid #cdd5dc;
          --area-of-interest-chip-font-color: #202122; /* Default font color */
          --area-of-interest-chip-font-size: 0.95rem;
          --area-of-interest-chip-font-weight: 400;
          --area-of-interest-chip-padding: 12px;
          --area-of-interest-chip-truncate-width: 280px;
          --area-of-interest-chip-line-height: 1.4rem;


          align-items: center;
          background-color: var(--area-of-interest-chip-background-color);
          border: var(--area-of-interest-chip-border);
          border-radius: 6px;
          color: var(--area-of-interest-chip-font-color);
          display: inline-block;
          font-size: var(--area-of-interest-chip-font-size);
          overflow: hidden;
          padding: calc(var(--area-of-interest-chip-padding) / 3) var(--area-of-interest-chip-padding);
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :host([removable]) {
          padding-right: calc(var(--area-of-interest-chip-padding) / 2);
        }

        :host([has-icon]) {
          padding-left: calc(var(--area-of-interest-chip-padding) / 2);
        }


        .chip-container {
          line-height: var(--area-of-interest-chip-line-height);
        }

        .hype-icon-container {
          margin-right: calc(var(--area-of-interest-chip-padding) / 2);
        }

        #hype-icon {
          color: var(--d2l-color-celestine);
          vertical-align: sub;
        }

        #hype-icon.primary {
          color: var(--d2l-color-celestine-minus-1);
        }

        .area-of-interest-text {
          font-size: var(--area-of-interest-chip-font-size);
          font-weight: var(--area-of-interest-chip-font-weight);
          margin: 0;
        }

        :host([truncate]) {
          max-width: var(--area-of-interest-chip-truncate-width);
        }


        .remove-button {
          margin-left: calc(var(--area-of-interest-chip-padding) / 2);
        }

        d2l-button-icon {
          --d2l-button-icon-fill-color: var(--d2l-color-chromite);
          --d2l-button-icon-min-height: 1.2rem;
          --d2l-button-icon-min-width: 1.2rem;
        }

        @media (max-width: 767px) {
          :host {
            --area-of-interest-chip-padding: 6px;

            border-radius: 4px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.removable = false;
    this.skill = null;
    this.text = '';
    this.truncate = false;
    this._hasIcon = null;
  }

  get _icon() {
    return 'area-of-interest';
  }

  get _iconColorClass() {
    return 'primary';
  }

  updated() {
    super.updated();
  }

  render() {
    if (!this.areaOfInterest && !this.text) {
      return nothing;
    }
    return this._chipTemplate(this.text || this.areaOfInterest.name);
  }

  _customSvg(icon) {
    if (icon === 'area-of-interest') {
      return html`
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_768_18369)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1313 14.7181L24.9718 13.5976C24.342 9.17188 20.8319 5.66763 16.4043 5.04625L15.2812 4.88863V1.58587C15.2812 1.43208 15.1534 1.30435 14.9997 1.30435C14.8456 1.30435 14.7181 1.43171 14.7181 1.58587V4.88865L13.5949 5.04611C9.16522 5.66704 5.65459 9.17195 5.02474 13.5982L4.86528 14.7188H1.58587C1.43176 14.7188 1.30435 14.8462 1.30435 15.0003C1.30435 15.1545 1.43176 15.2819 1.58587 15.2819H4.8636L5.01925 16.4076C5.63387 20.8526 9.15306 24.3769 13.5955 24.9995L14.7188 25.1569V28.4141C14.7188 28.5683 14.8462 28.6956 15.0003 28.6956C15.1544 28.6956 15.2819 28.5683 15.2819 28.4141V25.1567L16.4048 24.999C20.8461 24.3753 24.364 20.8513 24.978 16.407L25.1335 15.2812H28.4141C28.5682 15.2812 28.6957 15.1538 28.6957 14.9996C28.6957 14.8455 28.5683 14.7181 28.4141 14.7181H25.1313ZM28.4141 13.4138H26.2632C25.5515 8.41286 21.5888 4.45672 16.5855 3.75456V1.58587C16.5855 0.711709 15.8738 0 14.9997 0C14.1248 0 13.4138 0.711709 13.4138 1.58587V3.75439C8.4084 4.45603 4.44512 8.41287 3.7334 13.4145H1.58587C0.711018 13.4145 0 14.1262 0 15.0003C0 15.8745 0.711018 16.5862 1.58587 16.5862H3.7272C4.42171 21.6091 8.39463 25.5878 13.4145 26.2912V28.4141C13.4145 29.2883 14.1255 30 15.0003 30C15.8752 30 16.5862 29.2883 16.5862 28.4141V26.2907C21.6047 25.5859 25.5762 21.6076 26.27 16.5855H28.4141C29.289 16.5855 30 15.8738 30 14.9996C30 14.1255 29.289 13.4138 28.4141 13.4138Z" fill="#004489"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0001 22.4405C19.1093 22.4405 22.4405 19.1093 22.4405 15.0001C22.4405 10.891 19.1093 7.55982 15.0001 7.55982C10.891 7.55982 7.55982 10.891 7.55982 15.0001C7.55982 19.1093 10.891 22.4405 15.0001 22.4405ZM15.0001 23.7932C19.8564 23.7932 23.7932 19.8564 23.7932 15.0001C23.7932 10.1438 19.8564 6.20703 15.0001 6.20703C10.1438 6.20703 6.20703 10.1438 6.20703 15.0001C6.20703 19.8564 10.1438 23.7932 15.0001 23.7932Z" fill="#004489"/>
          </g>
          <defs>
            <clipPath id="clip0_768_18369">
              <rect width="30" height="30" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      `;
    }
    return nothing;
  }

  _handleRemoveClick() {
    const removeEvent = new CustomEvent('nova-chip-remove', {
      detail: { skill: this.skill },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(removeEvent);
  }

  _iconTemplate(icon) {
    if (icon === '') {
      this._hasIcon = false;
      return nothing;
    }

    this._hasIcon = true;
    let inner = html`
      <d2l-icon id="hype-icon" class=${this._iconColorClass} icon="tier1:${icon}"></d2l-icon>
    `;

    if (['area-of-interest'].includes(icon)) {
      inner = html`
        <d2l-icon-custom id="hype-icon" size="tier2" class=${this._iconColorClass}>
          ${this._customSvg(icon)}
        </d2l-icon-custom>
      `;
    }

    return html`
      <span class="hype-icon-container">
        ${inner}
      </span>
    `;
  }

  _removeButtonTemplate() {
    if (this.removable) {
      return html`
        <d2l-button-icon
          class="remove-button"
          @click="${this._handleRemoveClick}"
          icon="tier1:close-small"
          tabindex="-1"
          text=${this.localize('area-of-interest-chip.remove-button.text')}>
        </d2l-button-icon>
      `;
    }
    return nothing;
  }

  _chipTemplate(displayText) {
    return html`
      <span class="chip-container" title=${this.titleText ?? displayText}>
        ${this._iconTemplate(this._icon)}
        <span class="area-of-interest-text">${displayText}</span>
        ${this._removeButtonTemplate()}
      </span>
    `;
  }
}

window.customElements.define('area-of-interest-chip', AreaOfInterestChip);
