import ActivitiesHelper from '../../helpers/activities.js';
import { APPROVAL_MODELS } from '../schema/tenant/index.js';
import { Cost } from '../cost.js';
import { DATE_TIMES } from '../../constants.js';
import { idToSlug } from '../../methods.js';
import { localizeStartDate } from '../schema/activity/shared.js';
import { novaLocalize } from '../../l10n/localize.js';
import { NovaModel } from '../nova-model.js';
import { Tags } from '../../../app/shared/models/tags/tags.js';

import { APPLICATION_TAGS_LIST, ApplicationSchema } from '../schema/application/index.js';
import { fiscalYearDates, formatIsoDate, isIsoDateTimeLocallyToday } from '../../helpers/dateTime.js';

export const statuses = {
  PENDING: {
    label: 'pending',
    langKey: 'application.status.pending',
    indicator: { state: 'default' },
    wizard: { step: 1, labelId: 0 },
    sortOrder: 0,
  },
  ONE_OF_TWO_APPROVED: {
    label: 'oneOfTwoApproved',
    langKey: 'application.status.oneOfTwoApproved',
    indicator: { state: 'default' },
    wizard: { step: 1, labelId: 0 },
    sortOrder: 1,
  },
  APPROVED: {
    label: 'approved',
    langKey: 'application.status.approved',
    indicator: { state: 'success' },
    wizard: { step: 2, labelId: 1 },
    sortOrder: 2,
  },
  REGISTRATION_SUBMITTED: {
    label: 'registrationSubmitted',
    langKey: 'application.status.registrationSubmitted',
    indicator: { state: 'default' },
    wizard: { step: 2, labelId: 1 },
    sortOrder: 3,
  },
  DECLINED: {
    label: 'declined',
    langKey: 'application.status.declined',
    indicator: { state: 'alert' },
    wizard: { step: 1, labelId: 0 },
    sortOrder: 4,
  },
  REGISTERED: {
    label: 'registered',
    langKey: 'application.status.registered',
    indicator: { state: 'success' },
    wizard: { step: 3, labelId: 2 },
    sortOrder: 5,
  },
  WITHDRAWN: {
    label: 'withdrawn',
    langKey: 'application.status.withdrew',
    indicator: { state: 'alert' },
    wizard: { step: 3, labelId: 2 },
    sortOrder: 6,
  },
  CANCELLED_BEFORE_REGISTRATION: {
    label: 'cancelled_before_registration',
    langKey: 'application.status.cancelled',
    indicator: { state: 'alert' },
    wizard: { step: 2, labelId: 1 },
    sortOrder: 7,
  },
  CANCELLED_AFTER_REGISTRATION: {
    label: 'cancelled_after_registration',
    langKey: 'application.status.cancelled',
    indicator: { state: 'alert' },
    wizard: { step: 3, labelId: 2 },
    sortOrder: 8,
  },
  FAILED: {
    label: 'failed',
    langKey: 'application.status.failed',
    indicator: { state: 'alert' },
    wizard: { step: 3, labelId: 2 },
    sortOrder: 9,
  },
  COMPLETED: {
    label: 'completed',
    langKey: 'application.status.completed',
    indicator: { state: 'success' },
    wizard: { step: 4, labelId: 3 },
    sortOrder: 10,
  },

  getStatus(label) {
    const ret = Object.values(statuses).find(s => s.label === label);
    if (!ret) throw new Error(`Status ${label} does not exist`);
    return ret;
  },

  getStatusBySortOrder(order) {
    // force order to be a number in case it's a string
    order = Number(order);
    const ret = Object.values(statuses).find(s => s.sortOrder === order);
    if (!ret) throw new Error(`Status with order ${order} does not exist`);
    return ret;
  },
};

export class Application extends NovaModel {

  constructor(base = {}) {
    super('application', base);
    this.tags = new Tags(APPLICATION_TAGS_LIST, base.tags);
    if ((this.id || this.id === 0) && !this.slug) {
      this.slug = idToSlug(this.id);
    }
  }

  get status() {
    if (this._status) return this._status;

    if (this.isDeclined) return statuses.DECLINED;

    if (this.isPassed) return statuses.COMPLETED;
    if (this.isFailed) return statuses.FAILED;

    if (this.isCancelled && this.isRegistrationComplete)
      return statuses.CANCELLED_AFTER_REGISTRATION;

    if (this.isCancelled && !this.isRegistrationComplete)
      return statuses.CANCELLED_BEFORE_REGISTRATION;

    if (this.isWithdrawn) return statuses.WITHDRAWN;
    if (this.isRegistrationComplete) return statuses.REGISTERED;

    if (this.isPaid) return statuses.REGISTRATION_SUBMITTED;
    if (this.isApproved) return statuses.APPROVED;
    if (this.isApprovedByFirstApproverOnly) return statuses.ONE_OF_TWO_APPROVED;

    return statuses.PENDING;
  }

  set status(appStatus) {
    this._status = appStatus;
  }

  get canReapply() {
    return this.isCancelled || this.isDeclined || this.isFailed || this.isWithdrawn || this.isPassed;
  }

  get isApproved() {
    if (this.approvalModel === 'hybrid') {
      return this.sponsorApproval.approvalState === 'approved' && this.managerApproval.approvalState === 'approved';
    }
    return this.managerApproval.approvalState === 'approved';
  }

  /**
   * returns true if request is approved by only one approver, either manager or sponsor.
   */
  get isApprovedByFirstApproverOnly() {
    return !this.managerApproval.approvalState && this.sponsorApproval.approvalState === 'approved' ||
      this.managerApproval.approvalState === 'approved' && !this.sponsorApproval.approvalState;
  }

  get isApprovedOnly() { // Approved, but not registered or cancelled before registration
    return this.isApproved && !this.isRegistrationComplete && (!this.completionStatus || this.completionStatus === 'Pending');
  }

  get isCancelled() {
    return !!this.completedDate && this.completionStatus === 'Cancelled';
  }

  get isCancelledAfterRegistration() {
    return this.isCancelled && this.isRegistrationComplete;
  }

  get isCancelledBeforeRegistration() {
    return this.isCancelled && !this.isRegistrationComplete;
  }

  get isDeclined() {
    if (this.approvalModel === 'hybrid') {
      return this.managerApproval.approvalState === 'declined' || this.sponsorApproval.approvalState === 'declined';
    }
    return this.managerApproval.approvalState === 'declined';
  }

  get isFailed() {
    return !!this.completedDate && this.completionStatus === 'Fail';
  }

  get isPaid() {
    return !!this.paymentDate;
  }

  get isPaidOnly() { // Paid, but not registered or cancelled before registration
    return this.isApprovedOnly && !!this.paymentDate;
  }

  get isPassed() {
    return !!this.completedDate && this.completionStatus === 'Pass';
  }

  get isPending() {
    return !this.isDeclined && !this.isApproved && !this.isCancelled;
  }

  get isRegistrationComplete() {
    return !!this.enrollDate;
  }

  get isRegistrationCompleteOnly() {
    return !!this.enrollDate && (!this.completionStatus || this.completionStatus === 'Pending');
  }

  get isVisibleToProvider() {
    const { APPROVED, CANCELLED_BEFORE_REGISTRATION, DECLINED, PENDING } = statuses;
    if (this.status === CANCELLED_BEFORE_REGISTRATION) return this.cancelledBy === 'Provider';
    return !([APPROVED, DECLINED, PENDING].includes(this.status));
  }

  get isWithdrawn() {
    return this.completionStatus === 'Withdrawn';
  }

  get location() {
    return this.transaction.location;
  }

  get notMyApprover() {
    return this.requestedApprover !== this.user.managerEmail && this.approvalModel !== APPROVAL_MODELS.centralized;
  }

  get paymentDate() {
    return this.transaction?.paymentDate;
  }

  get postTaxActivityCost() {
    return this.activity.tempCost.inDollars({ taxRate: this.taxMultiplier });
  }

  get activityCostWithTax() {
    return this.activity.tempCost.applyRate(this.taxMultiplier);
  }

  get employeeCostWithTax() {
    return this.activityCostWithTax.subtract(this.tempApprovedAmount);
  }

  localizeStartDate(date) {
    return formatIsoDate(date, false, 'monthYear');
  }

  get startDate() {
    const { startDate, startDateType } = this.activity;
    let applicationStartDate = startDate;
    if (!this.isPending && !this.isApprovedOnly) applicationStartDate = 'activity.startDate.notApplicable';
    else if (startDateType === 'anytime') applicationStartDate = 'activity.startDate.anytime';
    else if (startDateType === 'comingSoon') applicationStartDate = 'activity.startDate.comingSoon';
    else if (!this.activity.isScheduled() && startDateType !== 'date') applicationStartDate = 'activity.startDate.notScheduled';
    const nextSession = this.nextSessionDateWhenPaid ? localizeStartDate(this.nextSessionDateWhenPaid) : localizeStartDate(ActivitiesHelper.getNextSessionDateAsString(startDate));
    if (nextSession === '') return novaLocalize('activity.startDate.comingSoon');
    return applicationStartDate;
  }

  get stripeCheckoutSessionId() {
    return this.transaction.stripeCheckoutSessionId;
  }

  get supportsReimbursement() {
    return !this.discountCode;
  }

  get tax() {
    return this.transaction?.tax;
  }

  get taxMultiplier() {
    if (!this.taxable) return 1;
    return 1 + (this.tax.percent / 100);
  }

  get taxable() {
    if (!this.tax) return false;
    return this.tax.enabled;
  }

  get userGuid() {
    return this.user.guid;
  }
  get userId() {
    return this.user.userId || this._userId;
  }

  getApprovers(employerTenant) {
    const requestedApprover = employerTenant.determineRequestedApprover(this.user);
    // if hybrid employer, requested approver is manager, need to also add program sponsor
    const approvers = [requestedApprover];
    if (employerTenant.approvalModel === 'hybrid') approvers.push(employerTenant.programSponsor);

    return approvers.map(approver => approver.displayName || approver.id).filter(approver => approver);
  }

  getTranslatedApprovers(employerTenant, term, extraFormatters = {}) {
    const approvers = this.getApprovers(employerTenant);
    if (approvers.length > 1) {
      return novaLocalize(`${term}.double`, {
        approverOne: approvers[0],
        approverTwo: approvers[1],
        ...extraFormatters,
      });
    }
    return novaLocalize(`${term}.single`, {
      approver: approvers[0],
      ...extraFormatters,
    }) || approvers[0];
  }

  isApprovedByBudgetHolder(budgetHolder = 'manager') {
    const approvedBySponsorBudgetHolder = budgetHolder === 'sponsor' && this.sponsorApproval?.approvalState === 'approved';
    const approvedByManagerBudgetHolder = budgetHolder === 'manager' && this.managerApproval?.approvalState === 'approved';
    return approvedByManagerBudgetHolder || approvedBySponsorBudgetHolder;
  }

  get conversionRateFromEmployerToProvider() {
    if (!this.isApproved) return undefined;
    return this.tempApprovedAmount.conversionRate;
  }

  get conversionRateFromProviderToEmployer() {
    if (!this.isApproved) return undefined;
    if (this.conversionRateFromEmployerToProvider === 0) return 0;
    return 1 / this.conversionRateFromEmployerToProvider;
  }

  get employerOperatingCurrency() {
    return this.employer?.operatingCurrency;
  }

  get refundRate() {
    return this.refundPct ? this.refundPct / 100 : 0;
  }

  get employerRefund() {
    return this.costToCompany().applyRate(this.refundRate);
  }

  get employeeRefund() {
    return this.costToEmployee().applyRate(this.refundRate);
  }

  // Activity cost if application is approved
  getActivityCost() {
    return this.activity.tempCost;
  }

  // Activity cost if application is approved
  getActivityCostWithTax() {
    return this.activity.tempCost.applyRate(this.taxMultiplier);
  }

  taxAmount() {
    return this.activity.tempCost.applyRate(this.taxMultiplier).subtract(this.activity.tempCost);
  }

  costToEmployee() {
    if (!this.isApproved) return new Cost();

    if (!this.transaction.costToEmployee.isEmpty()) {
      return this.transaction.costToEmployee;
    }

    return this.getActivityCostWithTax()
      .subtract(this.tempApprovedAmount)
      .inOriginalCurrency();
  }

  costToCompany() {
    if (!this.isApproved) return new Cost();
    return this.tempApprovedAmount;
  }

  getSchema() {
    return ApplicationSchema;
  }

  getSlug() {
    return idToSlug(this.id);
  }

  hasManagerResponded() {
    return !!this.managerApproval.approvedBy;
  }

  hasSponsorResponded() {
    return !!this.sponsorApproval.approvedBy;
  }

  isFromThisFiscalYear(fiscalYearStart) {
    const { startDate, endDate } = fiscalYearDates(fiscalYearStart);

    const appDateObj = new Date(this.applicationDate);
    return startDate <= appDateObj && appDateObj <= endDate;
  }

  hasTag(tag) {
    return this.tags.hasTag(tag);
  }

  setTag(tag, value) {
    return this.tags.setTag(tag, value);
  }

  /**
   * Returns the number of days since the application was submitted as well
   * as the lang key to associate with that number of days.
   * If the num days can't be calculated (e.g. missing applicationDate) then
   * the langTermKey returned will be undefined.
   * @returns {Object} - E.g. { langTermKey: 'application-table-row.days.today', 0 }
   */
  submittedTextLangTermData() {
    const xDate = this.applicationDate;
    const now = new Date();
    const xDateObj = new Date(xDate);
    const xTime = (now.getTime() - xDateObj.getTime()) / DATE_TIMES.millisecondsIn1Day;

    const numDays = Math.floor(xTime);
    let langTermKey;
    if (isIsoDateTimeLocallyToday(xDate)) {
      langTermKey = 'application-table-row.days.today';
    } else if (numDays === 1) {
      langTermKey = 'application-table-row.days.past.singular';
    } else if (numDays > 1) {
      langTermKey = 'application-table-row.days.past.plural';
    }

    return {
      numDays,
      langTermKey,
    };
  }
}

// TODO: this should get its own file
export function sortApplication() {
  return (a, b) => {
    const { sortOrder: sortOrderA } = a.status;
    const { sortOrder: sortOrderB } = b.status;
    if (sortOrderA < sortOrderB) {
      return -1;
    } else if (sortOrderA > sortOrderB) {
      return 1;
    } else {
      const aDate = new Date(a.applicationDate);
      const bDate = new Date(b.applicationDate);
      if (aDate < bDate) {
        return -1;
      } else if (aDate > bDate) {
        return 1;
      } else {
        return 0;
      }
    }
  };
}

export class ApplicationHelpers {

  static isApproved(approvalModel, sponsorApproval, managerApproval) {
    if (approvalModel === 'hybrid') {
      return sponsorApproval?.approvalState === 'approved' && managerApproval?.approvalState === 'approved';
    }
    return managerApproval?.approvalState === 'approved';
  }

  static isDeclined(approvalModel, managerApproval, sponsorApproval) {
    if (approvalModel === 'hybrid') {
      return managerApproval?.approvalState === 'declined' ||
        sponsorApproval?.approvalState === 'declined';
    }
    return managerApproval?.approvalState === 'declined';
  }

  static isCancelled(completedDate, completionStatus) {
    return !!completedDate && completionStatus === 'Cancelled';
  }

  static isWithdrawn(completionStatus) {
    return completionStatus === 'Withdrawn';
  }

  static isRegistrationComplete(enrollDate) {
    return !!enrollDate;
  }

  static isCancelledBeforeRegistration(isCancelled, isRegistrationComplete) {
    return isCancelled && !isRegistrationComplete;
  }

  static isPaid(paymentDate) {
    return !!paymentDate;
  }

  static isPassed(completedDate, completionStatus) {
    return !!completedDate && completionStatus === 'Pass';
  }

  static isFailed(completedDate, completionStatus) {
    return !!completedDate && completionStatus === 'Fail';
  }

  static isApprovedOnly(approvalModel, sponsorApproval, managerApproval, enrollDate, completionStatus) {
    return this.isApproved(approvalModel, sponsorApproval, managerApproval) &&
           !this.isRegistrationComplete(enrollDate) &&
           (!completionStatus || completionStatus === 'Pending');
  }

  static isPaidOnly(isApprovedOnly, paymentDate) {
    return isApprovedOnly && !!paymentDate;
  }

  static isPending(isDeclined, isApproved, isCancelled) {
    return !isDeclined && !isApproved && !isCancelled;
  }

  static tax(transaction) {
    return transaction?.tax;
  }

  static location(transaction) {
    return transaction?.location;
  }

  static taxable(transaction) {
    if (!this.tax(transaction)) return false;
    return transaction?.tax?.enabled;
  }

  static taxMultiplier(transaction) {
    if (!this.taxable(transaction)) return 1;
    return 1 + (this.tax(transaction)?.percent / 100);
  }

  static taxAmount(transaction, tempCost) {
    return tempCost.applyRate(this.taxMultiplier(transaction)).subtract(tempCost);
  }

  static getActivityCost(tempCost) {
    return tempCost;
  }

  static getActivityCostWithTax(tempCost, transaction) {
    const taxMultiplier = this.taxMultiplier(transaction);
    return tempCost.applyRate(taxMultiplier);
  }

  static costToCompany(approvalModel, sponsorApproval, managerApproval, tempApprovedAmount) {
    if (!this.isApproved(approvalModel, sponsorApproval, managerApproval)) return new Cost();
    return tempApprovedAmount;
  }

  static costToEmployee(approvalModel, sponsorApproval, managerApproval, transaction, tempCost, tempApprovedAmount) {
    if (!this.isApproved(approvalModel, sponsorApproval, managerApproval)) return new Cost();

    if (!transaction?.costToEmployee?.isEmpty()) {
      return transaction?.costToEmployee;
    }

    return this.getActivityCostWithTax(tempCost, transaction)
      .subtract(tempApprovedAmount)
      .inOriginalCurrency();
  }

  static conversionRateFromEmployerToProvider(isApproved, tempApprovedAmount) {
    if (!isApproved) return undefined;
    return tempApprovedAmount.conversionRate;
  }

  static conversionRateFromProviderToEmployer(isApproved, conversionRateFromEmployerToProvider) {
    if (!isApproved) return undefined;
    if (conversionRateFromEmployerToProvider === 0) return 0;
    return 1 / conversionRateFromEmployerToProvider;
  }

  static refundRate(refundPct) {
    return refundPct ? refundPct / 100 : 0;
  }

  static employerRefund(costToCompany, refundPct) {
    return costToCompany.applyRate(this.refundRate(refundPct));
  }

  static employeeRefund(costToEmployee, refundPct) {
    return costToEmployee.applyRate(this.refundRate(refundPct));
  }
}
