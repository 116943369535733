import '@brightspace-ui/core/components/icons/icon-custom.js';
import '../../../shared/components/skills/skill-chip-list/skill-chip-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { checkboxStyles } from '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

class SkillCategoryListCard extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      /* skillCategoryID */
      cardId: { type: Number },
      /* card header label that goes next to the checkbox */
      checkboxLabel: { type: String },
      /* boolean for if the card is "selected" or not */
      selected: { type: Boolean },
    };
  }

  static get styles() {
    return [
      checkboxStyles,
      heading1Styles,
      heading2Styles,
      css`
        :host {
          display: flex;
          height: 100%;
        }

        .item-wrapper {
          border: 1px solid var(--d2l-color-mica);
          border-radius: 8px;
          flex-grow: 1;
          padding: 18px;
        }

        .item-wrapper:hover {
          background:
            linear-gradient(to right, #ffffff, #ffffff),
            linear-gradient(to right, #2ba1f5, #0666aa);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          border: 3px solid transparent;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 16px;
        }

        .item-wrapper:hover input[type='checkbox'] {
          border-color: var(--d2l-color-celestine);
          border-width: 2px;
          outline-width: 0;
        }

        .selected {
          background-color: var(--d2l-color-celestine-plus-2);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 8px;
        }

        .list-item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .header-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.9rem;
        }

        .checkbox-wrapper {
          display: flex;
        }

        .min-width {
          min-width: 24px;
        }

        .category-label {
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          font-weight: 700;
          padding-left: 10px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.cardId = null;
    this.checkboxLabel = null;
    this.selected = false;
  }

  render() {
    if (!this.cardId || !this.checkboxLabel) {
      return nothing;
    }
    return this._cardTemplate();
  }

  _cardTemplate() {
    return html`
      <div id=${this.cardId} class="item-wrapper${this.selected ? ' selected' : ''}" @click=${this.cardSelected}>
        <div class="header-row">
          <div class="checkbox-wrapper">
            <input type="checkbox" aria-label=${this.checkboxLabel} class="d2l-input-checkbox min-width" id=${this.cardId} ?checked="${this.selected}" @onClick=${this.cardSelected}>
            <div class="category-label">
              ${this.checkboxLabel}
            </div>
          </div>
          <d2l-icon-custom size="tier2"><img alt="" src="../../assets/img/target-tier-2-${this.selected ? 'cestine' : 'corundum'}.svg">
          </d2l-icon-custom>
        </div>
        <slot name="sub-section"></slot>
      </div>
    `;
  }

  cardSelected() {
    const cardSelectedEvent = new CustomEvent('card-selected', {
      detail: {
        id: this.cardId,
      },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(cardSelectedEvent);
  }

}

window.customElements.define('nova-checkbox-skill-category-card', SkillCategoryListCard);
