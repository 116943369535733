import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/icons/icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import './skill-popper.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../shared/methods.js';

export default class GetStarted extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      _jobTitleId: { type: String },
      _jobTitleName: { type: String },
      _editingTitle: { type: Boolean },
      _relevantSkills: { type: Array },
      _titleMissing: { type: Boolean },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      css`
        :host {
          display: block;
        }

        .get-started-wrapper {
          display: flex;
          justify-content: space-around;
        }

        .left-side {
          display: flex;
          flex-direction: column;
          margin-top: 12%;
        }

        .hello-header {
          font-size: 55px;
          font-weight: 700;
        }

        .hello-subheader {
          font-size: 30px;
          font-weight: 400;
          margin: 0;
        }

        .no-title-subheader-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          min-width: fit-content;
          padding: 4px 12px;
        }

        .coloured-header {
          color: #008cff;
        }

        .highlight-title {
          background-color: var(--d2l-color-celestine-plus-2);
          padding: 0 6px;
        }

        .section-label {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0.5rem;
          margin-top: 1.5rem;
        }

        .title-card {
          min-width: 550px;
          width: fit-content;
        }

        .layout {
          display: flex;
          justify-content: space-between;
          padding: 12px 18px;
        }

        .content {
          margin-bottom: auto;
          margin-top: auto;
        }

        .industry-subheader {
          color: var(--d2l-color-galena);
          font-size: 14px;
          font-weight: 400;
        }

        .role-block {
          background: #f1f5fb;
          border-radius: 8px;
          display: flex;
          height: 60px;
          justify-content: space-between;
          padding-left: 18px;
        }

        .edit-icon {
          align-self: center;
          padding-right: 0.5rem;
        }

        .submit-button {
          margin-top: 2.5rem;
          width: 135px;
        }

        .flex {
          display: flex;
        }

        @media (max-width: 768px) {
          .flex {
            display: block;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._relevantSkills = [];
    this._titleMissing = false;
    this.suggestions = {};
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._jobTitleId = this.session?.user.getSetting('selectedTitleId');
    this._jobTitleName = this.session?.user.getSetting('selectedTitleName');
    this._jobSOC = this.session?.user.getSetting('selectedTitleSOC');

    // normalize if we have a title coming in
    if ((!this._jobSOC || !this._jobTitleId || !this._jobTitleName) && !!this.session.user.title) {
      const normalized = await this.client.similarTitles(this.session.user.title);

      if (normalized.data.title.id === 'ET0000000000000000') {
        this._editingTitle = true;
        return;
      }

      this._jobTitleName = normalized.data.title.name;
      this._jobTitleId = normalized.data.title.id;
      this._jobSOC = normalized.data.title.mapping.socs.length > 0 ? normalized.data.title.mapping.socs[0].name : '';
    } else if (!this.session.user.title) {
      this._titleMissing = true;
    }

    this._relevantSkills = (await this.client.skillsByTitleId(this._jobTitleId)).skills.slice(0, 8);
    this._editingTitle = !this._jobTitleName;
  }

  async setTitle() {
    const search = this.shadowRoot.getElementById('search');
    const searchTitle = search.value.split('(')[0].trim();
    const jobId = this.suggestions[searchTitle].id;
    this._relevantSkills = (await this.client.skillsByTitleId(jobId)).skills.slice(0, 8);
    this._jobTitleName = searchTitle;
    this._jobTitleId = jobId;
    this._jobSOC = this.suggestions[searchTitle].mapping.length > 0 ? this.suggestions[searchTitle].mapping[0].name : '';
    this._editingTitle = false;
    this.requestUpdate();
  }

  async _setUserTitle() {
    const titleElement = this.shadowRoot.getElementById('title');
    const submittedTitle = titleElement.value;
    this.session.user.title = submittedTitle;

    this._editingTitle = true;
    this._titleMissing = false;
  }

  async _searchChange(e) {
    const autoComplete = this.shadowRoot.getElementById('autoComplete');
    this.suggestions = e.detail.value ? await this.client.searchLightcast(e.detail.value, 'title') : [];
    const suggestionNames = this.suggestions.map(s => ({ value: `${s.name}${s.mapping.length > 0 ? ` (${s?.mapping[0].name})` : '' }` }));
    this.suggestions = mapify(this.suggestions, 'name');
    autoComplete.setSuggestions(suggestionNames);
  }

  _stopInputPropagation(e) {
    const [HOME, END] = [36, 35];
    const { keyCode } = e;
    if (keyCode === HOME || keyCode === END) {
      e.stopPropagation();
    }
  }

  _stopDropdowmPropagation(e) {
    e.stopPropagation();
  }

  _handleEditTitleClicked() {
    this._editingTitle = true;
  }

  _handleSubmission() {
    this.session.user.userSelectedJobTitleId = this._jobTitleId;
    this.session.user.userSelectedJobTitleName = this._jobTitleName;
    this.session.user.userSelectedJobTitleSOC = this._jobSOC;
    const submissionEvent = new CustomEvent('get-started-submitted', {
      detail: { userSelectedJobTitleId: this._jobTitleId, userSelectedJobTitleName: this._jobTitleName, userSelectedJobTitleSOC: this._jobSOC },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(submissionEvent);
  }

  _truncateString(string = '', maxLength = 30) {
    return string.length > maxLength
      ? `${string.substring(0, maxLength)}…`
      : string;
  }

  render() {
    return html`
      <div class="get-started-wrapper">
      <div class="left-side">
        <h1 class="d2l-heading-1 hello-header"><span class="coloured-header">${this.localize('view-onboarding.getStarted.headerHighlight')}</span>, ${this.localize('view-onboarding.getStarted.header')}</h1>
        ${this._titleMissing ? html`
          <div class="flex">
            <div class="no-title-subheader-wrapper">
              <h2 class="d2l-heading-2 hello-subheader">${this.localize('view-onboarding.getStarted.subheader', { name: this.session.user.getDisplayName() })}</h2>
            </div>
            <d2l-input-text id="title" label="${this.localize('view-onboarding.getStarted.currentRoleLabel')}*"></d2l-input-text>
            <d2l-button-icon style="margin-top: auto;" icon="tier2:check" text="Save" @click="${this._setUserTitle}"></d2l-button-icon>
          </div>
        ` : html`
        <h2 class="d2l-heading-2 hello-subheader">${this.localize('view-onboarding.getStarted.subheader', { name: this.session.user.getDisplayName() })} <span class="highlight-title"> ${this.session.user.title } </span></h2>`}

        ${!this._titleMissing ? html`
          <p class="section-label">${this.localize('view-onboarding.getStarted.closestMatchLabel')}*</p>
          <div style="min-height: 200px;">
            ${!this._editingTitle ? html`
              <nova-card class="title-card" no-padding compact>
                <div class="layout" slot="primary">
                  <div class="content">
                    <h3 class="d2l-heading-4" style="margin: 0;">${this._truncateString(this._jobTitleName)}</h3>
                    ${this._jobSOC ? html`<div class="industry-subheader">${this._truncateString(this._jobSOC, 50)}</div>` : nothing}
                  </div>
                  <d2l-button-subtle text="Edit" @click=${this._handleEditTitleClicked}></d2l-button-subtle>
                </div>
              </nova-card>` : html`
              <d2l-labs-autocomplete
                id="autoComplete"
                role="search"
                show-on-focus
                remote-source
                style="margin-bottom: 2rem;"
                @d2l-labs-autocomplete-filter-change=${this._searchChange}
                @d2l-labs-autocomplete-suggestion-selected=${this.setTitle}>
                <d2l-input-search
                  id="search"
                  label="Title"
                  class="title-card"
                  @keydown=${this._stopInputPropagation}
                  @d2l-input-search-searched=${this._searchChange}
                  slot="input">
                </d2l-input-search>
              </d2l-labs-autocomplete>`}

      ${this._relevantSkills.length > 0 && !this._editingTitle ? html`
        <p>Identifying key skills for your role:</p>
        <skill-popper
          .skills=${this._relevantSkills?.map(skill => ({ ...skill, isRelatedToRole: true }))}>
        </skill-popper>
      ` : nothing}

        <d2l-button class="submit-button" @click=${this._handleSubmission} primary>${this.localize('view-onboarding.getStarted.buttonText')}</d2l-button>
        ` : nothing}
      </div>
      <img src="../../../../assets/img/all-set.png" alt="all-set-graphic">
      </div>
    `;
  }
}

window.customElements.define('get-started', GetStarted);
