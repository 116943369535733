import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';

export default class ViewErrorLoading extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      query: { type: Object },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          height: 100vh;
          overflow-y: auto;
        }


        .splash {
          background: #f7fafe;
          background-image: url(../../../assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
          border-top: solid 6px var(--d2l-branding-primary-color);
          box-sizing: border-box;
          display: grid;
          min-height: 100%;
          padding: 1rem;
          place-items: center;
          width: 100%;
        }

        #wave-logo {
          display: block;
        }

        .card-content {
          display: grid;
        }
        .error-message {
          max-width: 550px;
        }

`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
  }

  render() {
    const logoUrl = this.session?.appDetails?.imageUrl || '../../../assets/img/d2l-wave.svg';
    const message = this.localize(`error.code.${this.query?.code}`) || this.query?.msg || this.localize('error.error-loading');
    return html`
      <div class="splash">
        <nova-card>
          <div class="card-content" slot="primary">
            <img id="wave-logo" alt="client-logo" height="40px" src="${logoUrl}">
            <p class="error-message">${message}</p>
          </div>
        </nova-card>
      </div>
    `;
  }

}

window.customElements.define('view-error-loading', ViewErrorLoading);
