import { css, html, LitElement } from 'lit';

import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import './get-started.js';
import './onboarding-personalization-flow.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewOnboarding extends LocalizeNova(RequesterMixin(nav(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _selectedJobTitleId: { type: String },
      _selectedJobTitleName: { type: String },
      _selectedJobTitleSOC: { type: String },
      _generatingResults: { state: true },
      _onboarding: { state: true },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._onboarding = false;
    this._generatingResults = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  get getStartedTemplate() {
    return html`<get-started @get-started-submitted=${this._getStartedSubmitted}></get-started>`;
  }

  _getStartedSubmitted(e) {
    this._onboarding = true;
    this._selectedJobTitleId = e.detail.userSelectedJobTitleId;
    this._selectedJobTitleName = e.detail.userSelectedJobTitleName;
    this._selectedJobTitleSOC = e.detail.userSelectedJobTitleSOC;
  }

  get onboardingTemplate() {
    return html`<onboarding-personalization-flow @go-to-get-started=${this.goToGetStarted} @go-to-generating-results=${this.goToGeneratingResults}></onboarding-personalization-flow>`;
  }

  goToGetStarted() {
    this._onboarding = false;
  }

  async goToGeneratingResults(e) {
    this._generatingResults = true;
    this.session.user.flaggedTitles = e.detail.selectedOtherTitles.map(title => {
      return { id: title.id, name: title.name };
    });
    this.session.user.selectedSkillCategories = e.detail.selectedSkillSubcategories;
    this.session.user.selectedSkills = e.detail.selectedSkills;
    await this.client.setUserSetting('selectedTitleId', this._selectedJobTitleId);
    await this.client.setUserSetting('selectedTitleName', this._selectedJobTitleName);
    await this.client.setUserSetting('selectedTitleSOC', this._selectedJobTitleSOC);
    await this.client.saveSkillProfile({ skills: e.detail.selectedSkills, skillCategories: e.detail.selectedSkillSubcategories, titles: e.detail.selectedOtherTitles });
    await this.client.setUser(this.session.user);
    this.session.user.skillsProfile = {
      skills: e.detail.selectedSkills,
      skillCategories: e.detail.selectedSkillSubcategories,
      titles: e.detail.selectedOtherTitles,
    };
    this.navigateWithoutHistory('/');
  }

  get _generatingResultsHtml() {
    // todo: switch this with an animation
    return html`
      <div>Generating results...</div>
    `;
  }

  render() {
    if (this._generatingResults) return this._generatingResultsHtml;
    if (this._onboarding) return this.onboardingTemplate;
    return this.getStartedTemplate;
  }
}

window.customElements.define('view-onboarding', ViewOnboarding);
