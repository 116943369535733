import {
  convertUTCToLocalDateTime,
  formatDate,
  formatDateTime,
  getDateFromISODate,
  getDateFromISODateTime,
  getToday,
  parseISODateTime } from '../lib/dateTime.js';

/**
 * Formats the date in the specified format. Defaults to 'medium'
 * @param {*} isoDate
 * @param {boolean=} includeTime
 * @param {string=} format
 */
export function formatIsoDate(isoDate, includeTime = false, format = 'medium') {
  // can pass in format = 'yearShortMonth' to get the date like this: 'yyyy-MM'
  // (which is the format needed for currency conversion)
  if (!isoDate) return '';
  return includeTime ? formatDateTime(getDateFromISODateTime(isoDate), { format }) : formatDate(getDateFromISODate(isoDate), { format });
}

export function fiscalYearDates(fiscalYearStart) {
  const dates = fiscalYearStart.split('-').map(p => parseInt(p));
  const [, fiscalMonth, fiscalDay] = dates;

  // Set the start date
  const startDate = new Date();
  startDate.setMonth(fiscalMonth - 1);
  startDate.setDate(fiscalDay);
  startDate.setHours(0, 0, 0, 0);

  // Determine the year for the fiscal year start
  if (startDate > new Date()) {
    startDate.setFullYear(startDate.getFullYear() - 1);
  }

  // Set the end date based on the start date
  const endDate = new Date(startDate);
  endDate.setFullYear(startDate.getFullYear() + 1);
  endDate.setDate(startDate.getDate() - 1);
  endDate.setHours(23, 59, 59, 999);

  return { startDate, endDate };
}

/**
 * Calculate and return the Unix timestamp for the start of the current UTC month in milliseconds.
 * @returns {number} - Example if date is Sept 1, 2022: 1661990400000
 */
export function getStartOfMonthTimestamp() {
  const now = new Date();
  const startOfUTCMonth = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1);
  return startOfUTCMonth;
}

/**
 * Calculate and return the Unix timestamp for the start of the previous UTC month in milliseconds.
 * @returns {number} - Example if date is Oct 20th, 2022: 1661990400000
 */
export function getStartOfPreviousMonthTimestamp() {
  const now = new Date();
  now.setUTCDate(0);
  const startOfUTCMonth = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1);
  return startOfUTCMonth;
}

/**
 * This converts the date to "YYYY-MM-DD" format for the minValue property of d2l-input-date.
 * @param {Date} date
 * @returns Date in "YYYY-MM-DD" format
 */
export function convertDateToYMD(date) {
  return date.toISOString().split('T')[0];
}

/**
 * Checks if give iso formatted date (i.e. YYYY-MM-DDTHH:mm:ss.sssZ)
 * fall within the same day in local time
 * @param {string} - isoDateTime in YYYY-MM-DDTHH:mm:ss.sssZ format
 * @returns {boolean} - true if isoDateTime is locally today, else false
 */
export function isIsoDateTimeLocallyToday(isoDateTime) {
  const localDateTime = convertUTCToLocalDateTime(parseISODateTime(isoDateTime));
  const today = getToday();
  return localDateTime.date === today.date
    && localDateTime.month === today.month
    && localDateTime.year === today.year;
}

/**
 * Takes the date from the d2l-input-date and converts to an ISO String
 * with optional hour time offset.
 * @param {string} - date string in format YYYY-MM-DD, e.g. 2023-12-25
 * @param {number} - hour of day in local time, to set the time. Default is 24 (nearest local midnight in future)
 * @returns {string} - isoDateTime in YYYY-MM-DDTHH:mm:ss.sssZ format
 */
export function d2lInputDateValueToIsoDate(dateString, hour = 24) {
  const date = new Date(dateString); // initialized w/ midnight utc
  date.setHours(hour);
  return date.toISOString();
}

/**
 * Takes a parsable date as input, compares it with the current date.
 * Returns true if date is in the past. Otherwise returns false.
 * @param {string} - date string in a parsable JS Date formate (e.g. 'YYYY-MM-DDTHH:MM:SS.sssZ')
 * @returns {boolean} - true if date is in the past, otherwise false
 */
export function isDateInThePast(dateToCheck) {
  return Date.parse(dateToCheck) - Date.now() < 0;
}

export function getLocalizedDateIgnoreTimezone(isoDate) {
  const dateWithoutTimezone = isoDate.split('T')[0];
  const date = new Date(dateWithoutTimezone.split('-'));
  return date.toLocaleString('default', { month: 'long', day:'numeric', year:'numeric' });
}

/**
 * @param {string} dateString The date to get the beginning of the day for
 * @returns {string} ISO 8601 combined date and time format string representing the beginning of the same day
 */
export function getStartOfDay(dateString) {
  const date = new Date(dateString);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1, 0).toISOString();
}

/**
 * @param {string} dateString The date to subtract days from
 * @param {Number} numberOfDays The number of days to subtract from the date
 * @returns {string} ISO 8601 combined date and time format string representing the date with days subtracted
 */
export function subtractDays(dateString, numberOfDays) {
  const earlierDate = new Date(dateString);
  earlierDate.setDate(earlierDate.getDate() - numberOfDays);

  return earlierDate.toISOString();
}

export function secondsToIsoString(seconds) {
  return new Date(seconds * 1000).toISOString();
}

export function isoStringToSeconds(isoString) {
  return Math.floor(new Date(isoString).getTime() / 1000);
}
