import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';

import '../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../../../../shared/components/general/nova-card/nova-card.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { sharedC2AStyles } from './shared-styles.js';

class ApproverCallout extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      application: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      sharedC2AStyles,
      css`
        .approved-text-display {
          align-items: center;
          display: grid;
          gap: 0.1rem 0.6rem;
          grid-auto-flow: row;
          grid-template-columns: auto 1fr;
          grid-template-rows: auto auto;
        }

        .approved-text-display > .d2l-heading-3 {
          margin: 0;
        }

        .approved-text-content {
          grid-column-start: 2;
          grid-row-start: 2;
        }

        .approved-check {
          color: var(--d2l-color-celestine);
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    if (this.application.approvalModel === 'hybrid' && this.application.isApprovedByFirstApproverOnly) {
      return html`
          <nova-card ?skeleton="${this.skeleton}" class="call-to-action c2a-gray">
            <div slot="primary">
              ${this.getTemplateByRole()}
            </div>
          </nova-card>
        `;
    } else {
      return nothing;
    }
  }

  /**
   * Returns "role" of last approver
   */
  get _approverRole() {
    return this.application?.managerApproval.approvalState === 'approved' ? 'manager' : 'sponsor';
  }

  /**
   * True if the current user is viewing an application they just approved.
   */
  get _approverIsViewing() {
    const { managerApproval, sponsorApproval } = this.application;
    const currentUserPersona = this.session.user.getPersona().split('_')[1];
    return (managerApproval.approvalState === 'approved' && currentUserPersona === 'employee' ||
    sponsorApproval.approvalState === 'approved' && currentUserPersona === 'sponsor');
  }

  templateForFirstApprover(role) {
    return html`
      <div class="approved-text-display">
        <d2l-icon icon="tier1:check-circle" class="approved-check"></d2l-icon>
        <h3 class="approved-text-heading d2l-heading-3">
           <!-- You have approved this request -->
          ${this.localize('application-call-to-action.template.approver.firstApprover.heading')}
        </h3>
        <div class="approved-text-content">
          <!-- // The manager/sponsor still needs to review... -->
          ${this.localize('application-call-to-action.template.approver.firstApprover', { role })}
        </div>
      </div>
    `;
  }

  templateForSecondApprover(role) {
    // The manager/sponsor approved this request
    return this.localize('application-call-to-action.template.approver.secondApprover', { role });
  }

  getTemplateByRole() {
    // The template text differs according to who is viewing and who has approved first
    let firstApproverRole, secondApproverRole;
    switch (this._approverRole) {
      // if the last approver was manager
      case 'manager': {
        firstApproverRole = 'manager';
        secondApproverRole = 'sponsor';
        break;
      }
      // if the last approver was sponsor
      case 'sponsor': {
        firstApproverRole = 'sponsor';
        secondApproverRole = 'manager';
        break;
      }
    }
    if (this._approverIsViewing) {
      // if the user that approved is viewing the approved request
      return this.templateForFirstApprover(secondApproverRole);
    } else {
      // otherwise, the approver that is viewing has not approved yet
      return this.templateForSecondApprover(firstApproverRole);
    }
  }

}

window.customElements.define('approver-callout', ApproverCallout);
