import { getDisplayName } from '../methods.js';
import { isEmail } from '../helpers/util.js';
import { NovaModel } from './nova-model.js';

import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';

export const PERSONAS = {
  EMPLOYER_EMPLOYEE: 'employer_employee', // Includes the employer manager persona
  EMPLOYER_SPONSOR: 'employer_sponsor',
  EMPLOYER_WATCHER: 'employer_watcher',
  PROVIDER_ADMIN: 'provider_admin',
  ADMIN_ADMIN: 'admin_admin',
};
const { EMPLOYER_EMPLOYEE, EMPLOYER_SPONSOR, EMPLOYER_WATCHER, PROVIDER_ADMIN, ADMIN_ADMIN } = PERSONAS;

export const GUEST_REGEX = new RegExp('guest[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');

const CORE_ATTRIBUTES = [
  'byoe',
  'country',
  'postalCode',
  'department',
  'displayName',
  'email',
  'firstName',
  'guid',
  'imageUrl',
  'lastName',
  'lightcastJobTitleId',
  'managerEmail',
  'managerName',
  'province',
  'state',
  'postalCode',
  'tenantDomain',
  'tenantId',
  'title',
  'userId',
  'thirdPartyUserId',
  'extraAttributes',
];

class UserSessionModelSchema extends NovaSchema {
  constructor() {
    super('userSession');
    this.attributes = {
      authorization: new SchemaAttribute(),
      companyName: new SchemaAttribute(),
      state: new SchemaAttribute(),
      country: new SchemaAttribute(),
      postalCode: new SchemaAttribute(),
      email: new SchemaAttribute(),
      freshdeskId: new SchemaAttribute(),
      freshdeskCompanyId: new SchemaAttribute(),
      department: new SchemaAttribute(),
      displayName: new SchemaAttribute(),
      firstName: new SchemaAttribute(),
      lastName: new SchemaAttribute(),
      imageUrl: new SchemaAttribute(),
      extraInfoCollected: new SchemaAttribute(),
      settings: new SchemaAttribute(),
      tenantType: new SchemaAttribute(),
      entitlements: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      title: new SchemaAttribute(),
      userId: new SchemaAttribute(),
      thirdPartyUserId: new SchemaAttribute(),
      managerEmail: new SchemaAttribute(),
      managerName: new SchemaAttribute(),
      lastLoginTime: new SchemaAttribute(),
      apiUrl: new SchemaAttribute(),
      guid: new SchemaAttribute(),
      exp: new SchemaAttribute(),
      iat: new SchemaAttribute(),
      tenantDomain: new SchemaAttribute(),
      lastUsedTaxIds: new SchemaAttribute(),
      byoe: new SchemaAttribute(),
      ttl: new SchemaAttribute(),
      active: new SchemaAttribute(true),
      lightcastJobTitleId: new SchemaAttribute(),
      flaggedTitles: new SchemaAttribute(),
    };
    this.setAllowExtraAttributes();
    this.modelAttributes = {
      skillsProfile: new SchemaAttribute(),
    };
  }
}

export const UserSessionSchema = new UserSessionModelSchema();
const lowercaseAttributes = ['email', 'managerEmail', 'tenantDomain', 'userId', 'thirdPartyUserId'];
export default class UserSession extends NovaModel {

  constructor(base = {}) {
    super('userSession', base);
    if (base.thirdPartyUserId) this.userId = base.thirdPartyUserId; // FUSS is using a clearer name of thirdPartyUserId for this attribute. We will slowly migrate to this name.

    lowercaseAttributes.forEach(attr => {
      if (this[attr]) this[attr] = this[attr].toLowerCase();
    });
  }

  get loggedIn() {
    return !!this.userId;
  }

  get isGuest() {
    return GUEST_REGEX.test(this.userId);
  }

  formatPostalCode(code) {
    return code.replace(' ', '').toUpperCase();
  }

  getEmail(strict = false) {
    if (this.email) return this.email;
    if (!strict && isEmail(this.userId)) return this.userId;
    return undefined;
  }

  getPostalCode() {
    if (this.postalCode) return this.postalCode;
    return undefined;
  }

  getDisplayName() {
    return getDisplayName(this);
  }

  getPersona() {
    switch (this.tenantType) {
      case 'employer': {
        if (this.hasEntitlement('sponsor')) return EMPLOYER_SPONSOR;
        else if (this.hasEntitlement('watcher')) return EMPLOYER_WATCHER;
        else return EMPLOYER_EMPLOYEE; // employee or manager; there is no way to determine if the user is a manager using UserSession
      }
      case 'provider': {
        return PROVIDER_ADMIN;
      }
      case 'admin': {
        return ADMIN_ADMIN;
      }
    }
  }

  getSchema() {
    return UserSessionSchema;
  }

  /**
   * Provide numeric salt for seeding purposes for OpenSearch calls, etc.
   * Assists with providing consistent call results for a singular session
   * Seeds are similar across users for OpenSearch caching purposes.
   * The seed should change each hour for new sessions.
   * This function makes the seed out of the user's last login time.
   * e.g. '2023-07-26T19:41:14.255Z' -> '2023072619'
   */
  getSessionSeed() {
    // use last login time so it stays the same for the current session
    const newSeed = new Date(this.lastLoginTime).toISOString()
      .substring(0, 13) // keep year, month, date and hour only
      .replace(/\D/g, ''); // strip out non-digit character

    return parseInt(newSeed);
  }

  getExtraAttribute(attribute) {
    if (!attribute) return undefined;
    if (!this.extraAttributes || this.extraAttributes === undefined) return undefined;
    return this.extraAttributes[attribute];
  }

  setExtraAttribute(attribute, value) {
    if (!attribute) return;
    if (!this.extraAttributes) this.extraAttributes = {};
    this.extraAttributes[attribute] = value;
  }

  getSetting(key) {
    if (!key) return undefined;
    if (!this.settings || this.settings === undefined) return undefined;
    return this.settings[key];
  }

  hasEntitlement(entitlement) {
    if (!entitlement) return false;
    const hasUndefinedEntitlements = this.entitlements === null || this.entitlements === undefined;
    return !!(hasUndefinedEntitlements ? false : this.entitlements.some(e => e === entitlement));
  }

  hasExtraAttribute(attribute) {
    if (!attribute) return false;
    return !!(this.extraAttributes === null || this.extraAttributes === undefined ? false : this.extraAttributes[attribute]);
  }

  hasSetting(key) {
    if (!key) return false;
    return !!(this.settings === null || this.settings === undefined ? false : this.settings[key]);
  }

  setSetting(key, value) {
    this.settings[key] = value;
  }
}

export const getCoreUser = user => {
  return new UserSession(CORE_ATTRIBUTES.reduce((acc, key) => {
    if (user[key] || user[key] === 0 || user[key] === false) acc[key] = user[key];
    return acc;
  }, {}));
};

export class UserSessionHelpers {

  static getEmail(email, userId, strict = false) {
    if (email) return email;
    if (!strict && isEmail(userId)) return userId;
    return undefined;
  }

  static hasEntitlement(entitlement, entitlements) {
    if (!entitlement) return false;
    const hasUndefinedEntitlements = entitlements === null || entitlements === undefined;
    return !!(hasUndefinedEntitlements ? false : entitlements.some(e => e === entitlement));
  }

  static getPersona(tenantType, entitlements) {
    switch (tenantType) {
      case 'employer': {
        if (UserSessionHelpers.hasEntitlement('sponsor', entitlements)) return EMPLOYER_SPONSOR;
        else if (UserSessionHelpers.hasEntitlement('watcher', entitlements)) return EMPLOYER_WATCHER;
        else return EMPLOYER_EMPLOYEE; // employee or manager; there is no way to determine if the user is a manager using UserSession
      }
      case 'provider': {
        return PROVIDER_ADMIN;
      }
      case 'admin': {
        return ADMIN_ADMIN;
      }
    }
  }
}
