import DOMPurify from 'dompurify';

import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/floating-buttons.js';
import '@brightspace-ui/core/components/dialog/dialog';
import '@brightspace-ui/core/components/empty-state/empty-state-simple.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/more-less/more-less.js';
import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import { html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

import { bodySmallStyles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import ActivitiesHelper from '../../../../shared/helpers/activities.js';
import { ActivitySchema } from '../../../../shared/models/schema/activity/index.js';
import EditActivityMixin from './edit-activity-mixin.js';
import { editActivityStyles } from './styles.js';
import { optionsTemplate } from '../../../shared/helpers/generic-templates/generic-templates.js';

import '../../../shared/components/activities/activity-skill-tagger/activity-skill-tagger.js';
import '../../../shared/components/activities/activity-small/activity-small.js';
import '../../../shared/components/skills/skill-chip-list/skill-chip-list.js';
import { getStreamPackagesList } from '../../../../shared/helpers/package-manager/packageDefinitions.js';

export default class EditActivity extends EditActivityMixin(LitElement) {
  static get styles() {
    return [
      bodySmallStyles,
      buttonStyles,
      editActivityStyles,
      heading2Styles,
      inputStyles,
      inputLabelStyles,
      selectStyles,
    ];
  }

  _checkboxGroupTemplate(tagListId, labelLangTerm, errorLangTerm) {
    const hasError = this._errors.includes(tagListId);

    return html`
      <fieldset class="checkbox-fieldset${hasError ? ' fieldset-error' : ''}" @change=${this._updateTagList}>
        <legend class="d2l-input-label d2l-input-label-required">${this.localize(labelLangTerm)}</legend>
        <div class="checkbox-wrapper">
          ${ActivitySchema.getPossibleValues(tagListId).map(({ displayName, value }) => html`
            <d2l-input-checkbox
              aria-describedby=${errorLangTerm}
              ?checked="${this._activity[tagListId].hasTag(value)}"
              class="inlineCheckbox"
              id=${value}
              name=${tagListId}>${displayName}</d2l-input-checkbox>
          `)}
        </div>
        ${hasError ? html`<p id=${errorLangTerm}>${this.localize(errorLangTerm)}</p>` : nothing}
      </fieldset>
    `;
  }

  _checkboxInputTemplate(attribute, labelLangTerm, blurEffect) {
    return html`
      <d2l-input-checkbox @blur=${blurEffect} id="${attribute}" ?checked="${this._activity.hasTag(attribute)}">
        ${this.localize(labelLangTerm)}
      </d2l-input-checkbox>
    `;
  }

  get _floatingSaveButtons() {
    if (this._isNewActivity) {
      return html`
        <d2l-floating-buttons>
          <d2l-button primary type="button" @click="${this._submitAndClose}">${this.localize('edit-activity.createActivity.button')}</d2l-button>
        </d2l-floating-buttons>
      `;
    }

    return html`
      <d2l-floating-buttons>
        <d2l-button primary type="button" ?disabled="${this._extractingSkills}" @click="${this._submitAndStayOpen}">${this.localize('edit-activity.save.button')}</d2l-button>
        <d2l-button type="button" ?disabled="${this._extractingSkills}" @click="${this._submitAndClose}">${this.localize('edit-activity.saveAndClose.button')}</d2l-button>
      </d2l-floating-buttons>
    `;
  }

  _selectTemplate(attribute, labelLangTerm, options) {
    return html`
      <div class="select-wrapper ">
        <label for="${attribute}" class="d2l-input-label d2l-input-label-required">${this.localize(labelLangTerm)}</label>
        <select id="${attribute}" class="d2l-input-select edit-activity-select" .value="${this._activity[attribute] ?? ''}" @blur="${this._blurred}" required>
          <option disabled selected value=""> -- ${this.localize('edit-activity.select')} -- </option>
          ${options ?? optionsTemplate(ActivitySchema.getPossibleValues(attribute))}
        </select>
      </div>
    `;
  }

  _sessionDateTemplate() {

    const nextSessionDate = ActivitiesHelper.getNextSessionDateAsString(this._activity.startDate);

    return this._activity.startDateType === 'date' ? html`
    <div class="date-select-wrapper">
      <span style="padding-right: 5%;">${this.localize('view-activity.editStartDate.date.list')}</span>
      <d2l-input-date
        id="startDate"
        label="${this.localize('view-activity.startDate')}">
      </d2l-input-date>
    </div>
    <d2l-list class="date-list">
      ${repeat(this._activity.startDate, startDate => startDate, (startDate, index) => html`
        <d2l-list-item .value=${startDate}>
          <d2l-list-item-content>
            <div>${(new Date(startDate)).toLocaleString('en-US', { day: 'numeric', month: 'long', timeZone: 'UTC', year: 'numeric' })}</div>
            ${ this._activity.startDate[index] === nextSessionDate ? html`<div slot="supporting-info"><d2l-status-indicator state="default" text="Current Date"></d2l-status-indicator></div>` : nothing}
          </d2l-list-item-content>
          <div slot="actions">
            <d2l-button-icon id=${index} icon="tier1:close-default" @click=${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._handleDateDeletion(startDate)}>
            </d2l-button-icon>
          </div>
        </d2l-list-item>
      `)}
    </d2l-list>
    ` : nothing;
  }

  _textInputTemplate(attribute, labelLangTerm, isRequired, inputType) {
    return html`
      <d2l-input-text
        @blur=${this._blurred}
        id="${attribute}"
        label="${this.localize(labelLangTerm)}"
        name="${attribute}"
        ?required=${isRequired}
        type="${ifDefined(inputType)}"
        value="${this._activity[attribute] ?? ''}"></d2l-input-text>
    `;
  }

  _admissionRequirement(requirement) {
    return html`
      <d2l-form class="admission-requirement-section" @change="${
  // eslint-disable-next-line lit/no-template-arrow
  e => this._changeAdmissionFormValue(e, requirement)}">
        ${this._renderCloseButton(requirement)}
        ${this._renderEditableLabel(requirement)}
        ${this._renderHtmlEditor(requirement)}
        ${this._renderAdmissionInputs(requirement)}
      </d2l-form>
    `;
  }

  _renderCloseButton(requirement) {
    const closeButton = html`
    <div class="admission-cancel-button">
      <d2l-button-icon icon="tier1:close-default" @click="${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._deleteRequirement(requirement.id, requirement.type)}"></d2l-button-icon>
    </div>`;
    if (requirement.type !== 'standard') return closeButton;

    return html `
    <div class="admission-cancel-button">
        <d2l-button-icon icon="tier1:close-default" @click="${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._deleteRequirement(requirement.id, requirement.type)}"></d2l-button-icon>
      </div>`;
  }

  _renderEditableLabel(requirement) {
    return html`
      <div class="changable-label">
        ${requirement.isEditing ?
    html`
            <d2l-input-text
              id="admissionTitle"
              required
              label="${this.localize('edit-activity.admissionBased.title')}"
              input-width="606px"
              .value="${requirement?.title}"
              @keyup="${
  // eslint-disable-next-line lit/no-template-arrow
  e => this._changeAdmissionTitle(e, requirement)}"
            >
              <d2l-button-icon
               ?disabled="${requirement.draftTitle?.length < 1}"
               slot="after"
               icon="tier1:check"
               text="Save"
               @click="${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._saveRequirementTitle(requirement)}"></d2l-button-icon>
            </d2l-input-text>
          `
    :
    html`
            <h4>${requirement?.title}</h4>
            <d2l-button-icon icon="tier1:edit" text="Edit" @click="${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._toggleEditing(requirement)}"></d2l-button-icon>
          `
}
      </div>
    `;
  }

  _renderHtmlEditor(requirement) {
    return html`
      <d2l-htmleditor
        id="admissionDescription"
        required
        label="${this.localize('edit-activity.admissionBased.requirements')}"
        html="${DOMPurify.sanitize(requirement?.description)}"
        @d2l-htmleditor-update="${
  // eslint-disable-next-line lit/no-template-arrow
  e => this._changeAdmissionFormValue(e, requirement)}"
        source-editable>
      </d2l-htmleditor>
    `;
  }

  _renderAdmissionInputs(requirement) {
    return html`
      <div class="admission-inputs">
        <d2l-input-number
          id="cost"
          class="admission-input"
          input-width="224px"
          max-fraction-digits="2"
          min="0"
          label="${this.localize('edit-activity.admissionBased.potentialFee')}"
          value=${requirement?.cost}
        ></d2l-input-number>
        <d2l-input-number
          id="delay"
          class="admission-input"
          input-width="224px"
          max-fraction-digits="1"
          min="0"
          label="${this.localize('edit-activity.admissionBased.delay')}"
          value=${requirement?.delay}
        ></d2l-input-number>
      </div>
    `;
  }

  _renderAdmissionContactEmail() {
    return html`
              <d2l-input-text
              id="admissionContactEmail"
              label="${this.localize('edit-activity.admissionBased.contactEmail')}"
              input-width="518px"
              .value="${this._admissionRequirements.contactEmail}"
              @keyup="${this._changeAdmissionContactEmail}"
              type="email"
            ></d2l-input-text>
    `;
  }

  _admissionBasedTemplate() {
    return html`
    <div class="admission-requirements-section">
      <span>${this.localize('edit-activity.admissionBased.requirementsAfterSave')}</span>
      ${this._renderAdmissionContactEmail()}
        <d2l-collapsible-panel ?expanded="${!this._admissionRequirements.standard.length}" panel-title="${this.localize('edit-activity.admissionBased.standardTitle')}">
        <d2l-collapsible-panel-summary-item
        slot="summary"
        text="${`${this._admissionRequirements.standard.length} ${this.localize('edit-activity.admissionBased.requirementsCount')}`}"
        >
      </d2l-collapsible-panel-summary-item>
      <p class="d2l-empty-state-description admission-panel-summary">${this.localize('edit-activity.admissionBased.standardSummary')}</p>
        <div class="admission-requirements-container">
          ${repeat(this._admissionRequirements.standard, requirement => requirement.id, requirement => this._admissionRequirement(requirement))}
      </div>
        <d2l-button-subtle
        icon="tier1:plus-large"
        text="${this.localize('edit-activity.admissionBased.addRequirement')}"
        @click=${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._addRequirement('standard')}></d2l-button-subtle>
        </d2l-collapsible-panel>
        <d2l-collapsible-panel ?expanded="${!this._admissionRequirements.international.length}" panel-title="${this.localize('edit-activity.admissionBased.internationalTitle')}">
        <d2l-collapsible-panel-summary-item
        slot="summary"
        text="${`${this._admissionRequirements.international.length} ${this.localize('edit-activity.admissionBased.internationalRequirementsCount')}`}">
      </d2l-collapsible-panel-summary-item>
        <p class="d2l-empty-state-description admission-panel-summary">${this.localize('edit-activity.admissionBased.internationalSummary')}</p>
          <div class="admission-requirements-container">
            ${repeat(this._admissionRequirements.international, requirement => requirement.id, requirement => this._admissionRequirement(requirement))}
          </div>
        <d2l-button-subtle
        icon="tier1:plus-large"
        text="${this.localize('edit-activity.admissionBased.addInternationalRequirement')}"
        @click=${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._addRequirement('international')}></d2l-button-subtle>
      </d2l-collapsible-panel>
    </div>
      `;
  }

  render() {
    const providers = this._providers ?? [];
    const sortedProviders = providers.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    const providerOptions = sortedProviders.map(provider => html`<option .value=${provider.id}>${provider.name}</option>`);
    const skills = [...this._activity.skills];
    const extractedSkills = skills.filter(skill => skill.extracted && !skill.omittedFromExtraction);
    const skillOmittedFromExtraction = skills.filter(skill => skill.omittedFromExtraction);
    const ifDef = val => val ?? '';
    const hasNoCategory = this._activity.category?.tagValues?.length === 0;
    const hasAllParentsInactive = !!this._activity.parents?.length && !this._activity.parents.some(({ tags }) => tags.hasTag('active'));
    const shouldDisableSwitch = hasNoCategory || hasAllParentsInactive;
    const tooltipMessage = hasNoCategory ? 'edit-activity.switch.tooltip.category' : 'edit-activity.switch.tooltip.inactive';

    return html`
      <h1 class="d2l-heading-2 main-heading">
        ${this.localize(this._isNewActivity ? 'general.addActivity' : 'edit-activity.title')}
        ${this._activity.id ? html`
          <d2l-switch
            id="active"
            ?disabled=${shouldDisableSwitch}
            @change="${this.changeActiveState}"
            ?on="${this._activity.hasTag('active')}"
            text="${this.localize('edit-activity.active.checkbox')}"
            text-position="hidden">
          </d2l-switch>
          ${shouldDisableSwitch ? html`<d2l-tooltip for="active">${this.localize(tooltipMessage)}</d2l-tooltip>` : nothing}
          - <d2l-link href=${`activities/${this._activity.id}`}>${this.localize('general.view')}</d2l-link>
        ` : nothing}
      </h1>
      <d2l-form id="edit-form" @change="${this._changeValue}">
        ${this._selectTemplate('type', 'edit-activity.type.label')}
        ${this._selectTemplate('provider', 'general.provider', providerOptions)}
        ${this._textInputTemplate('title', 'edit-activity.title.label', true)}
        ${this._textInputTemplate('code', 'edit-activity.code.label')}
        ${this._textInputTemplate('registration', 'edit-activity.registrationLink.label', true)}
        ${this._textInputTemplate('cardImageUrl', 'edit-activity.cardImageLink.label')}
        ${this._textInputTemplate('bannerImageUrl', 'edit-activity.bannerImageLink.label')}

        <d2l-htmleditor id="description"
          label="${this.localize('edit-activity.description.label')}"
          required
          html="${DOMPurify.sanitize(this._activity.description)}"
          @d2l-htmleditor-blur="${this._editorBlurred}"
          @d2l-htmleditor-update="${this._changeValue}"
          source-editable>
        </d2l-htmleditor>

        ${this._selectTemplate('instructionLang', 'edit-activity.instructionLang.label')}

        <d2l-input-textarea
          id="specialInstructions"
          label="${this.localize('edit-activity.specialInstructions.label')}"
          .value="${ifDef(this._activity.specialInstructions)}">
        </d2l-input-textarea>

        ${this._isProgram ? html`
          ${this._textInputTemplate('keyDifferentiator', 'edit-activity.keyDifferentiator.label', true)}
          ${this._textInputTemplate('coursesRequired', 'edit-activity.numCoursesRequired.label', true, 'number')}
          ${this._textInputTemplate('numberElectives', 'edit-activity.numberElectives.label', true, 'number')}
          ${this._textInputTemplate('prereq', 'edit-activity.prerequisites.label', true)}
          <span class="d2l-input-label">${this.localize('edit-activity.tags.label')}</span>
        ` : html`
          ${this._textInputTemplate('credits', 'edit-activity.credits.label', undefined, 'number')}
          <span class="d2l-input-label">${this.localize('edit-activity.tags.label')}</span>
        `}

        ${this._checkboxInputTemplate('allowRequest', 'edit-activity.allowRequesting.checkbox', this._blurred)}
        ${this._checkboxInputTemplate('taxable', 'edit-activity.taxable.checkbox', this._blurred)}
        ${this._checkboxInputTemplate('fastToComplete', 'edit-activity.fastToComplete.checkbox', this._blurred)}

        <span class="d2l-input-label">${this.localize('edit-activity.admissionBased.label')}</span>
        <div @change=${this._updateTag}>
          ${this._checkboxInputTemplate('admissionBased', 'edit-activity.admissionBased.checkbox', this._blurred)}
        </div>
        ${this._activity.hasTag('admissionBased') ?
    this._admissionBasedTemplate() :
    (this._admissionBasedCheckboxWasTriggered && !this.requirementsAreEmpty) ?
      html`
        <d2l-alert class="admission-alert" type="warning">
          <span>${this.localize('edit-activity.admissionBased.checkboxFlagUnchecked')}</span>
        </d2l-alert>` : nothing }


        <div class="category-wrapper" @change=${this._updateTagList}>
          <label for="category" class="d2l-input-label">${this.localize('edit-activity.category.label')}</label>
          ${ActivitySchema.getPossibleValues('category').map(({ displayName, value }) => html`
            <d2l-input-checkbox ?checked="${this._activity.category.hasTag(value)}" id=${value} name="category">
              ${displayName}
              ${value === 'myList' ? nothing : repeat(getStreamPackagesList(value), p => p.id, p => html`
                <d2l-status-indicator
                  state="default"
                  text="${this.localize(p.langTerm)}"
                ></d2l-status-indicator>
              `)}
            </d2l-input-checkbox>
          `)}
        </div>

        ${this._selectTemplate('certificateType', 'edit-activity.certificateType.label')}

        <d2l-input-number
          @blur=${this._blurred}
          ?required=${this._activity.needsCost}
          value="${ifDef(this._activity?.tempCost?.inDollars())}"
          id="cost"
          max-fraction-digits="2"
          min="0"
          input-width="289px"
          label="${this.localize('edit-activity.overallCost.label')}"></d2l-input-number>

        ${this._selectTemplate('duration', 'edit-activity.duration.label')}
        ${this._selectTemplate('delivery', 'edit-activity.delivery.label')}

        ${this._activity.isLocationBased ? html`
          ${this._textInputTemplate('postalCode', 'apply-activity.personalInfo.postalCode', true)}
          ${this._textInputTemplate('maxLearnerDistance', 'edit-activity.inPerson.maxLearnerDistance', true, 'number')}
        ` : nothing}

        ${this._selectTemplate('format', 'edit-activity.format.label')}

        <div class="select-wrapper">
          <label for="weeklyTimeCommitment" class="d2l-input-label d2l-input-label-required">${this.localize('edit-activity.timeCommitment.label')}</label>
          <select
            id="weeklyTimeCommitment"
            name="weeklyTimeCommitment"
            .value="${ifDef(this._activity.weeklyTimeCommitment)}"
            class="d2l-input-select edit-activity-select"
            aria-label="${this.localize('edit-activity.weeklyTimeCommitment')}"
            required>
            <option disabled selected value=""> -- ${this.localize('edit-activity.select')} -- </option>
            ${optionsTemplate(ActivitySchema.getPossibleValues('weeklyTimeCommitment'))}
          </select>
        </div>

        <d2l-collapsible-panel
          ?expanded=${true}
          panel-title=${this.localize('view-activity.editStartDate.date.list')}
        >
          <div class="select-wrapper">
            <label for="startDateType" class="d2l-input-label d2l-input-label-required">${this.localize('common.startDateType')}</label>
            <select
              id="startDateType"
              name="startDateType"
              .value="${this._activity.startDateType}"
              class="d2l-input-select edit-activity-date-type"
              aria-label="${this.localize('edit-activity.startDateType')}"
              required>
              <option disabled ?selected=${!this._isNotRequestableWithoutChildren} value="">${this.localize('common.startDateType')}</option>
              <option ?selected=${this._isNotRequestableWithoutChildren} value="anytime">${this.localize('edit-activity.startDateType.anytime')}</option>
              <option value="unknown">${this.localize('edit-activity.startDateType.unknown')}</option>
              <option value="comingSoon">${this.localize('edit-activity.startDateType.comingSoon')}</option>
              <option value="date">${this.localize('edit-activity.startDateType.date')}</option>
            </select>
          </div>

          ${this._sessionDateTemplate()}
        </d2l-collapsible-panel>

        ${this._isProgram ? html`
          <h2>${this.localize('edit-activity.courses')}</h2>
          <d2l-button-subtle @click=${this._openAddCourseDialog} text="${this.localize('edit-activity.addCourse.label')}" icon="tier2:add"></d2l-button-subtle>
        ` : html`
          ${this._checkboxGroupTemplate('feedbackTypes', 'edit-activity.feedbackTypes.checkbox', 'course.error.missingFeedbackType')}
          ${this._checkboxGroupTemplate('contentFormats', 'edit-activity.contentFormat.checkbox', 'course.error.missingContentFormat')}
        `}

        ${(this._isProgram && this._activity?.children?.length > 0) ? html`
          ${repeat(this._activity.children, activity => activity.id, activity => html`
            <activity-small class="course-item" .activity=${activity}>
              <div slot="actions">
                <d2l-status-indicator state="none" text="${activity.meta.order || this.localize('edit-activity.order.placeholder')}" bold></d2l-status-indicator>
                <d2l-status-indicator state="none" text="${activity.meta.type}" bold></d2l-status-indicator>
                <d2l-button-icon slot="actions" icon="tier1:close-small" @click=${this._courseRemoved(activity)} aria-label="${this.localize('edit-activity.removeCourse')}"></d2l-button-icon>
              </div>
            </activity-small>
          `)}
        ` : nothing}

        ${!this._isProgram && this._activity?.parents?.length > 0 ? html`
          <h2>${this.localize('activity-relationships.relatedProgramsHeader')}</h2>
          ${repeat(this._activity.parents, activity => activity.id, activity => html`
            <activity-small class="course-item" .activity=${activity}></activity-small>
          `)}
        ` : nothing}

        <div class="skills-wrapper">
          <h3>${this.localize('general.skills')}</h3>
          <h4>${this.localize('edit-activity.skills.header.manageExtracted')}</h4>
          <d2l-button ?disabled=${this._extractingSkills} @click=${this._extractSkills}>${this.localize('edit-activity.extractSkills.button')}</d2l-button>

            <h5>${this.localize('edit-activity.skills.header.extractedSkills')}</h5>
            ${this._extractingSkills ? html`
              <div class="d2l-dialog-content-loading">
                <d2l-loading-spinner size="100"></d2l-loading-spinner>
                <span class="d2l-input-label">${this.localize('edit-activity.extractSkills.pleaseWait')}</span>
              </div>` :
    html`

        ${extractedSkills.length > 0 ? html`
                <skill-chip-list removable @nova-chip-remove=${this._omitExtractedSkill} .skills=${extractedSkills}></skill-chip-list>
                ` : html`
                <d2l-empty-state-simple description="${this.localize('edit-activity.skills.extractedSkillsEmpty')}"></d2l-empty-state-simple>
              ` }
          <h5>${this.localize('edit-activity.removedSkills.header')}</h5>
          ${skillOmittedFromExtraction.length > 0 ? html`
            <skill-chip-list removable @nova-chip-remove=${this._allowSkillToBeExtracted} .skills=${skillOmittedFromExtraction}></skill-chip-list>
            ` : html`
            <d2l-empty-state-simple description="${this.localize('edit-activity.skills.omittedExtractedSkillsEmpty')}"></d2l-empty-state-simple>
            `}
          `}

          <hr>
          <h4>${this.localize('edit-activity.skills.header.manageOtherSkills')}</h4>
          <activity-skill-tagger
            id="skill-picker"
            .activity=${this._activity}
            fixed-width
            hide-extracted
            @skills-changed=${this._handleSkillsChanged}>
          </activity-skill-tagger>
          <hr>
          <h4>${this.localize('edit-activity.skills.header.preview')}</h4>
          ${this._activity.visibleSkills.length > 0 ? html`
            <skill-chip-list .skills=${this._activity.visibleSkills}></skill-chip-list>
            ` : html`
            <d2l-empty-state-simple description="${this.localize('edit-activity.skills.previewEmpty')}"></d2l-empty-state-simple>
            `}
        </div>
      </d2l-form>

      ${this._floatingSaveButtons}

      <d2l-dialog id="add-course-dialog" @change=${this.newCourseChange} title-text="${this.localize('edit-activity.addCourse.label')}">
        <div class="description">${this.localize('edit-activity.addCourses.prompt')}</div>
        <div class="dialog-attributes">
          <select id="course" class="d2l-input-select course-select" required>
            <option disabled selected value=""> -- ${this.localize('edit-activity.selectCourse')} -- </option>
            ${this.addCourseOptions.map(course => html`<option .value=${course.id}>${course.title}</option>`)};
          </select>
          <d2l-input-text
            id="order"
            type="number"
            label="${this.localize('edit-activity.order.label')}"
            novalidate></d2l-input-text>
          <div class="radio-wrapper">
            <span class="d2l-input-label d2l-input-label-required">${this.localize('edit-activity.coreOrElective.label')}</span>
            <label class="d2l-input-radio-label">
              <input id="core" name="coreOrElective" type="radio" class="d2l-input-radio" aria-label="core">
              ${this.localize('edit-activity.core')}
            </label>
            <label class="d2l-input-radio-label">
              <input id="elective" name="coreOrElective" checked type="radio" class="d2l-input-radio" aria-label="elective">
              ${this.localize('edit-activity.elective')}
            </label>
          </div>
        </div>
        <d2l-button id="approveButton" slot="footer" @click=${this._addCourse} primary data-dialog-action="done">${this.localize('edit-activity.addCourse.label')}</d2l-button>
        <d2l-button id="cancelButton" slot="footer" @click=${this._closeAddCourseDialog} data-dialog-action>${this.localize('edit-activity.cancel.button')}</d2l-button>
      </d2l-dialog>
    `;
  }
}

window.customElements.define('edit-activity', EditActivity);
