import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';

export default class ViewNotFound extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      route: { type: String, reflect: true, attribute: 'route' },
    };
  }

  static get styles() {
    return css`
      :host {
        border: solid 1px gray;
        display: block;
      }
`;
  }

  render() {
    return html`${this.localize('error.404')}`;
  }
}

window.customElements.define('view-not-found', ViewNotFound);
